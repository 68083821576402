.paw-img {
    width: 40%;
    max-width: 300px;
}

.title {
    color: #36B56C;
    font-size: 35px;
}

.btn-main {
    max-width: 300px;
    width: 80%;
    border-radius: 0;
    height: 45px;
    font-size: 15px;
    text-transform: uppercase;
    color: #fff;
    background: #2c3e50 !important;
    letter-spacing: 1px;
    box-shadow: 0 6px 10px rgb(136 136 136 / 16%);
}

