

@import "guest_components/global";

// Variables
@import 'variables';

// Packages
@import '~bootstrap/scss/bootstrap';
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~select2/dist/css/select2.css";
@import "~select2-bootstrap-theme/dist/select2-bootstrap.css";

//layouts
@import "guest_components/navigation";
//@import "guest_components/footer";

//components
//@import "guest_components/search";
//@import "guest_components/list";
//@import "guest_components/categories";
//@import "guest_components/form";
//@import "guest_components/loader";
//@import "guest_components/thanks";
//@import "guest_components/store_buttons";
//@import "guest_components/search-results";
//@import "guest_components/vet";
//@import "guest_components/why_us";
//@import "guest_components/diseases";
//@import "guest_components/services";
//@import "guest_components/how-it-works";
//@import "guest_components/toggle";
//@import "guest_components/ads";
@import "guest_components/register";


body{
    overflow-x: hidden;
    font-family: "Lato", "Helvetica Neue", Helvetica, sans-serif;
}

h1{
    font-size: 28px;
    font-weight: 400;
}
h2{
    font-size: 24px;
    font-weight: 900;
}
h3{
    font-size: 21px;
    font-weight: 900;

}
p{
    font-size: 16px;
}


