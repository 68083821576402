body{
    font-family: "Lato", Helvetica, Arial, sans-serif !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
}

.button {
    min-width: 196px;
    height: 45px;
    font-size: 16px;
    font-weight: 500;
    font-weight: 500;
    text-align: center;
    border-radius: 0 !important;
    outline: none !important;
    text-decoration: none !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: bottom;
    padding: 0 20px;
    @media(max-width: 767px){
        min-width: auto;
    }
}
.button--default {
    color: rgb(223 231 234);
    border: 1px solid rgb(213 223 235);
}
.button--primary {
    color: rgb(0 0 0);
    background-color: rgb(255 210 0);
    border: 1px solid rgb(255 210 0);
}

input, select, div, a {
    border-radius: 0 !important;
}
