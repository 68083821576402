.navbar{
    padding: 0;
    flex-flow: row;
    align-items: center;
    background-color: #fff;
    .navbar__brand {
        display: flex;
        width: 20%;
        background-color: #fff;
        @media (max-width: 1200px){
            width: 30%;
        }
        @media (max-width: 796px){
            width: 50%;
        }
        @media (max-width: 440px){
            width: 80%;
        }
        img {
        vertical-align: middle;
        border-style: none;
        padding: 0 20%;
        }
    }
    @media (max-width: 991px){
        flex-flow: column;
        height: 130px;
        .navbar__brand {
            img {
                padding-top: 13px;
            }
        }
    }

}
.wrap-nav {
    width: 80%;
    @media (max-width: 1200px){
        width: 70%;
    }
    @media (max-width: 991px){
        width: 100%;
    }
}
.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}
.navbar--upper{
    background-color: rgb(255 255 255);
    width: 100%;
    padding-left: 10px;
    @media (max-width: 767px){
        padding-left: 0;
        // display: none;
    }
}

.navbar__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 65px;
    width: 100%;
    line-height: 65px;
    @media (max-width: 767px) {
        height: 70px;
        line-height: 43px;
    }
    .navbar__utils {
        margin-left: 50px;
        a{
        font-size: 16px;
        color: #183f61;
        position: relative;
        // color: rgb(0 0 0) !important;
        }
        @media (max-width: 767px) {
            margin-left: 20px;
            a{
            font-size: 12px;
            }
        }
    }
    .navbar__brandname, .navbar__fordoctor{
        font-weight: 400;
        span {
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 0;
            height: 30px;
            width: 30px;
            border-radius: 50%;
            transform: translate(-120%, -50%);
            background-color: #d6d6d6;
            i {
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                left: 50%;
            }
            @media (max-width: 767px){
                display: none;
            }
        }
    }
    .navbar__brandname{
        font-size: 13px;
        margin-right: 60px;
        display: inline-block;
        @media (max-width: 767px){
            font-size: 12px;
            margin-right: 20px;
        }
    }
}
// .navbar--mobile{
//     display: none;
//     @media (max-width: 767px){
//         display: block;
//         width: 100%;
//         background-color: #FFF;
//         .navbar__controls{
//             display: flex;
//             align-items: center;
//             justify-content: space-between;
//             height: 65px;
//         }
//         .navbar__icon{
//             position: relative;
//             width: 26px;
//             height: 2px;
//             background-color: rgb(0 0 0);
//             display: inline-block;
//             border-radius: 40px;
//             cursor: pointer;
//             &::before{
//                 top: -9px;
//                 width: 26px;
//                 height: 2px;
//                 content: " ";
//                 display: block;
//                 position: absolute;
//                 left: 0;
//                 background-color: rgb(0 0 0);
//                 border-radius: 40px;
//             }
//             &::after{
//                 top: 9px;
//                 width: 26px;
//                 height: 2px;
//                 content: " ";
//                 display: block;
//                 position: absolute;
//                 left: 0;
//                 background-color: rgb(0 0 0);
//                 border-radius: 40px;
//             }
//         }
//         .navbar__mobile-brand {
//             margin-right: auto;
//             margin-left: 15px;
//         }
//     }
// }
.navbar--lower {
    background-color: #3cb4e6;
    width: 100%;
    @media (max-width: 767px){
        height: auto;
        background-color: #3cb4e6;
        box-shadow: 6px 8px 17px rgb(0 0 0 / 16%);
        // display: none;
    }
    .navbar__items {
        display: flex;
        align-items: center;
        justify-content: space-between !important;
        height: 64px;
        width: 100%;

        @media (max-width: 767px){
            // height: auto;
            // flex-flow: column;
            // align-items: flex-start;
            justify-content: space-between !important;
        }
        .download-app{
            height: inherit;
            display: flex;
            align-items: center;
            a{
                box-shadow: 0 4px 0 0 #298851;
                height: 46px;
                margin: 0 14px;
                &:hover{
                    box-shadow: 0 4px 0 0 #1a98cd;
                }
            }

            img{
                max-height: 46px;
                width: auto;
            }
            @media (max-width: 550px) {
                display: none;
            }
        }

        .navbar__menu {
            position: relative;
            .navbar__item {
                cursor: pointer;
                border-radius: 6px;
                color: #fff !important;
                // font-weight: 600;
                font-size: 16px;
                height: 37px;
                padding: 0 14px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                // letter-spacing: 1px;
                position: relative;
                &.active{
                    .navbar__dropdown{
                        display: block;
                    }
                }
                @media (max-width: 1199px){
                    padding: 0 10px;
                }
                @media (max-width: 991px){
                    letter-spacing: -0.1px;
                }
                @media (max-width: 767px){
                    margin: 3px 0;
                }
            }
            i {
                padding-left: 5px;
            }
        }

    }
}
.navbar__dropdown{
    background-color: rgb(255 255 255);
    box-shadow: 0 44px 25px rgb(0 0 0 / 2%);
    position: absolute;
    left: 2px;
    top: 41px;
    width: 209px !important;
    display: none;
    z-index: 1000;
    .navbar__dropdown-items{
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        overflow-y: auto;
        max-height: calc(100vh - 150px);
        .navbar__dropdown-item{
            display: flex;
            flex-flow: column;
            justify-content: flex-start;
            flex-basis: 100%;
            font-size: 15px;
            font-weight: 500;
            align-items: flex-start;
            color: rgb(0 0 0);
            padding: 8px 12px;
            line-height: 1.3;
            background-color: rgb(255 255 255);
        }
    }
    .navbar--shrink a {
        background-color: rgb(255 255 255);
    }
    .navbar__more {
        flex-basis: 100%;
        text-align: center;
        color: rgb(0 173 185);
        font-size: 15px;
        padding: 12px 0;
        cursor: pointer;
        font-weight: 500;
        border-top: 1px solid rgb(243 235 235);
        background-color: rgb(248 248 248);
    }
}
